import React, { FC, createContext, useState, PropsWithChildren } from "react";

type AppContextType = {
  isSideDrawerOpened: boolean;
  toggleSideDrawer: () => void;
};

export const defaultValue: AppContextType = {
  isSideDrawerOpened: false,
  toggleSideDrawer: () => false,
};

export const AppContext = createContext<AppContextType>(defaultValue);

type Props = PropsWithChildren;

const AppProvider: FC<Props> = ({ children }) => {
  const [isSideDrawerOpened, setIsSideDrawerOpened] = useState(false);

  const toggleSideDrawer = () => {
    setIsSideDrawerOpened((prev) => !prev);
  };

  return (
    <AppContext.Provider value={{ isSideDrawerOpened, toggleSideDrawer }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
